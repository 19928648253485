import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="wizard"></a>WIZARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG56_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any.</p>
    <p><b>Hit Die</b>: d4.</p>
    <h6>Class Skills</h6>
    <p className="initial">The wizard&#8217;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher Script</a> (Int), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(all skills, taken
individually) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), and <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <p><a id="table-the-wizard"></a><b>Table: Wizard</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "34%"
          }}>Special</th>
          <th colSpan="10" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "3%"
          }}>0</th>
          <th style={{
            "width": "3%"
          }}>1st</th>
          <th style={{
            "width": "3%"
          }}>2nd</th>
          <th style={{
            "width": "3%"
          }}>3rd</th>
          <th style={{
            "width": "3%"
          }}>4th</th>
          <th style={{
            "width": "3%"
          }}>5th</th>
          <th style={{
            "width": "3%"
          }}>6th</th>
          <th style={{
            "width": "3%"
          }}>7th</th>
          <th style={{
            "width": "3%"
          }}>8th</th>
          <th style={{
            "width": "3%"
          }}>9th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Summon familiar, Scribe Scroll</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Bonus feat</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>Bonus feat</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+7/+2</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
          <td>Bonus feat</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">Bonus feat</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="wizard-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
wizard.</p>
    <p><b>Weapon and Armor Proficiency</b>: Wizards are proficient
with the club, dagger, heavy crossbow, light crossbow, and
quarterstaff, but not with any type of armor or shield. Armor of any
type interferes with a wizard&#8217;s movements, which can cause her spells
with somatic components to fail.</p>
    <p><a id="wizard-spells"></a><b>Spells</b>: A wizard casts arcane spells which are drawn
from the <a style={{
        "color": "#579eb6"
      }} href="wizardSpells.html">sorcerer/ wizard spell list</a>. A wizard must choose and prepare
her spells ahead of time (see below).</p>
    <p>To learn, prepare, or cast a spell, the wizard must have an
Intelligence score equal to at least 10 + the spell level. The
Difficulty Class for a saving throw against a wizard&#8217;s spell is 10 +
the spell level + the wizard&#8217;s Intelligence modifier.</p>
    <p>Like other spellcasters, a wizard can cast only a certain
number of spells of each spell level per day. Her base daily spell
allotment is given on Table: The Wizard. In addition, she receives
bonus spells per day if she has a high Intelligence score.</p>
    <p>Unlike a bard or sorcerer, a wizard may know any number of
spells. She must choose and prepare her spells ahead of time by getting
a good night&#8217;s sleep and spending 1 hour studying her spellbook. While
studying, the wizard decides which spells to prepare.</p>
    <p><a id="wizard-bonus-languages"></a><b>Bonus Languages</b>: A wizard may substitute Draconic for
one of the bonus languages available to the character because of her
race.</p>
    <p><a id="wizard-familiar"></a><b>Familiar</b>: A wizard can obtain a familiar in exactly the
same manner as a sorcerer can. See the sorcerer description and the
information on Familiars below for details.</p>
    <p><a id="wizard-scribe-scroll"></a><b>Scribe Scroll</b>: At 1st level, a wizard gains <a href="featsAll.html#scribe-scroll" style={{
        "color": "rgb(87, 158, 182)"
      }}>Scribe
Scroll</a> as a bonus feat.</p>
    <p><b>Bonus Feats</b>: At 5th, 10th, 15th, and 20th level, a
wizard gains a bonus feat. At each such opportunity, she can choose a
metamagic feat, an item creation feat, or Spell Mastery. The wizard
must still meet all prerequisites for a bonus feat, including caster
level minimums.</p>
    <p>These bonus feats are in addition to the feat that a character
of any class gets from advancing levels. The wizard is not limited to
the categories of <a href="featsItc.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>item creation feats</a>, <a href="featsMtm.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>metamagic feats</a>,
or <a href="featsAll.html#spell-mastery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spell
Mastery</a> when choosing these feats.</p>
    <p><a id="wizard-spellbooks"></a><b>Spellbooks</b>: A wizard must study her spellbook each day
to prepare her spells. She cannot prepare any spell not recorded in her
spellbook, except for read magic, which all wizards can prepare from
memory.</p>
    <p>A wizard begins play with a spellbook containing all 0-level
wizard spells (except those from her prohibited school or schools, if
any; see School Specialization, below) plus three 1st-level spells of
your choice. For each point of Intelligence bonus the wizard has, the
spellbook holds one additional 1st-level spell of your choice. At each
new wizard level, she gains two new spells of any spell level or levels
that she can cast (based on her new wizard level) for her spellbook. At
any time, a wizard can also add spells found in other wizards&#8217;
spellbooks to her own.</p>
    <h5><a id="wizard-school-specialization"></a>SCHOOL SPECIALIZATION</h5>
    <p className="initial">A school is one of eight groupings of spells,
each defined by a common theme. If desired, a wizard may specialize in
one school of magic (see below). Specialization allows a wizard to cast
extra spells from her chosen school, but she then never learns to cast
spells from some other schools.</p>
    <p>A specialist wizard can prepare one additional spell of her
specialty school per spell level each day. She also gains a +2 bonus on
Spellcraft checks to learn the spells of her chosen school.</p>
    <p>The wizard must choose whether to specialize and, if she does
so, choose her specialty at 1st level. At this time, she must also give
up two other schools of magic (unless she chooses to specialize in
divination; see below), which become her prohibited schools. A wizard
can never give up divination to fulfill this requirement. Spells of the
prohibited school or schools are not available to the wizard, and she
can&#8217;t even cast such spells from scrolls or fire them from wands. She
may not change either her specialization or her prohibited schools
later.</p>
    <p>The eight schools of arcane magic are abjuration, conjuration,
divination, enchantment, evocation, illusion, necromancy, and
transmutation. Spells that do not fall into any of these schools are
called universal spells.</p>
    <p><a id="wizard-abjuration"></a><i>Abjuration</i>: Spells that protect, block, or banish. An
abjuration specialist is called an abjurer.</p>
    <p><a id="wizard-conjuration"></a><i>Conjuration</i>: Spells that bring creatures or materials
to the caster. A conjuration specialist is called a conjurer.</p>
    <p><a id="wizard-divination"></a><i>Divination</i>: Spells that reveal information. A
divination specialist is called a diviner. Unlike the other
specialists, a diviner must give up only one other school.</p>
    <p><a id="wizard-enchantment"></a><i>Enchantment</i>: Spells that imbue the recipient with some
property or grant the caster power over another being. An enchantment
specialist is called an enchanter.</p>
    <p><a id="wizard-evocation"></a><i>Evocation</i>: Spells that manipulate energy or create
something from nothing. An evocation specialist is called an evoker.</p>
    <p><a id="wizard-illusion"></a><i>Illusion</i>: Spells that alter perception or create false
images. An illusion specialist is called an illusionist.</p>
    <p><a id="wizard-necromancy"></a><i>Necromancy</i>: Spells that manipulate, create, or destroy
life or life force. A necromancy specialist is called a necromancer.</p>
    <p><a id="wizard-transmutation"></a><i>Transmutation</i>: Spells that transform the recipient
physically or change its properties in a more subtle way. A
transmutation specialist is called a transmuter.</p>
    <p><a id="wizard-universal"></a><i>Universal</i>: Not a school, but a category for spells that
all wizards can learn. A wizard cannot select universal as a specialty
school or as a prohibited school. Only a limited number of spells fall
into this category.</p>
    <h5><a id="familiars"></a>FAMILIARS</h5>
    <p className="initial">A familiar is a normal animal that gains new
powers and becomes a magical beast when summoned to service by a
sorcerer or wizard. It retains the appearance, Hit Dice, base attack
bonus, base save bonuses, skills, and feats of the normal animal it
once was, but it is treated as a magical beast instead of an animal for
the purpose of any effect that depends on its type. Only a normal,
unmodified animal may become a familiar. An animal companion cannot
also function as a familiar.</p>
    <p>A familiar also grants special abilities to its master (a
sorcerer or wizard), as given on the table below. These special
abilities apply only when the master and familiar are within 1 mile of
each other.</p>
    <p>Levels of different classes that are entitled to familiars
stack for the purpose of determining any familiar abilities that depend
on the master&#8217;s level.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th><a id="table-familiars"></a>Familiar</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>Bat</td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#listen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Listen</a>
checks</td>
        </tr>
        <tr>
          <td>Cat</td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#move-silently" style={{
              "color": "rgb(87, 158, 182)"
            }}>Move
Silently</a> checks</td>
        </tr>
        <tr className="odd-row">
          <td>Hawk</td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#spot" style={{
              "color": "rgb(87, 158, 182)"
            }}>Spot</a>
checks in bright light</td>
        </tr>
        <tr>
          <td>Lizard</td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a>
checks</td>
        </tr>
        <tr className="odd-row">
          <td>Owl</td>
          <td>Master gains a +3 bonus on Spot checks in shadows</td>
        </tr>
        <tr>
          <td>Rat</td>
          <td>Master gains a +2 bonus on Fortitude saves</td>
        </tr>
        <tr className="odd-row">
          <td>Raven<sup>1</sup></td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#appraise" style={{
              "color": "rgb(87, 158, 182)"
            }}>Appraise</a>
checks</td>
        </tr>
        <tr>
          <td>Snake<sup>2</sup></td>
          <td>Master gains a +3 bonus on <a href="skillsAll.html#bluff" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bluff</a>
checks</td>
        </tr>
        <tr className="odd-row">
          <td>Toad</td>
          <td>Master gains +3 hit points</td>
        </tr>
        <tr>
          <td className="last-row">Weasel</td>
          <td className="last-row">Master gains a +2 bonus on Reflex saves</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>A raven familiar can speak one
language of its master&#8217;s choice as a supernatural ability.</i></td>
        </tr>
        <tr>
          <td colSpan="2">2 <i>Tiny viper.</i></td>
        </tr>
      </tbody>
    </table>
    <p><a id="familiar-basics"></a><b>Familiar Basics</b>: Use the basic statistics for a
creature of the familiar&#8217;s kind, but make the following changes:</p>
    <p><i>Hit Dice</i>: For the purpose of effects related to number
of Hit Dice, use the master&#8217;s character level or the familiar&#8217;s normal
HD total, whichever is higher.</p>
    <p><i>Hit Points</i>: The familiar has one-half the master&#8217;s
total hit points (not including temporary hit points), rounded down,
regardless of its actual Hit Dice.</p>
    <p><i>Attacks</i>: Use the master&#8217;s base attack bonus, as
calculated from all his classes. Use the familiar&#8217;s Dexterity or
Strength modifier, whichever is greater, to get the familiar&#8217;s melee
attack bonus with natural weapons. Damage equals that of a normal
creature of the familiar&#8217;s kind.</p>
    <p><i>Saving Throws</i>: For each saving throw, use either the
familiar&#8217;s base save bonus (Fortitude +2, Reflex +2, Will +0) or the
master&#8217;s (as calculated from all his classes), whichever is better. The
familiar uses its own ability modifiers to saves, and it doesn&#8217;t share
any of the other bonuses that the master might have on saves.</p>
    <p><i>Skills</i>: For each skill in which either the master or
the familiar has ranks, use either the normal skill ranks for an animal
of that type or the master&#8217;s skill ranks, whichever are better. In
either case, the familiar uses its own ability modifiers. Regardless of
a familiar&#8217;s total skill modifiers, some skills may remain beyond the
familiar&#8217;s ability to use.</p>
    <p><b>Familiar Ability Descriptions</b>: All familiars have
special abilities (or impart abilities to their masters) depending on
the master&#8217;s combined level in classes that grant familiars, as shown
on the table below. The abilities given on the table are cumulative.</p>
    <p><i>Natural Armor Adj.</i>: The number noted here is an
improvement to the familiar&#8217;s existing natural armor bonus.</p>
    <p><i>Int</i>: The familiar&#8217;s Intelligence score.</p>
    <p><a id="familiar-alertness"></a><i>Alertness (Ex)</i>: While a familiar is within arm&#8217;s reach,
the master gains the <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a> feat.</p>
    <p><a id="familiar-improved-evasion"></a><i>Improved Evasion (Ex)</i>: When subjected to an attack that
normally allows a Reflex saving throw for half damage, a familiar takes
no damage if it makes a successful saving throw and half damage even if
the saving throw fails.</p>
    <p><a id="familiar-share-spells"></a><i>Share Spells</i>: At the master&#8217;s option, he may have any
spell (but not any spell-like ability) he casts on himself also affect
his familiar. The familiar must be within 5 feet at the time of casting
to receive the benefit. If the spell or effect has a duration other
than instantaneous, it stops affecting the familiar if it moves farther
than 5 feet away and will not affect the familiar again even if it
returns to the master before the duration expires. Additionally, the
master may cast a spell with a target of &#8220;You&#8221; on his familiar (as a
touch range spell) instead of on himself. A master and his familiar can
share spells even if the spells normally do not affect creatures of the
familiar&#8217;s type (magical beast).</p>
    <p><a id="familiar-empathic-link"></a><i>Empathic Link (Su)</i>: The master has an empathic link
with his familiar out to a distance of up to 1 mile. The master cannot
see through the familiar&#8217;s eyes, but they can communicate empathically.
Because of the limited nature of the link, only general emotional
content can be communicated.</p>
    <p>Because of this empathic link, the master has the same
connection to an item or place that his familiar does.</p>
    <p><a id="familiar-deliver-touch-spell"></a><i>Deliver Touch Spells (Su)</i>: If the master is 3rd level
or higher, a familiar can deliver touch spells for him. If the master
and the familiar are in contact at the time the master casts a touch
spell, he can designate his familiar as the &#8220;toucher.&#8221; The familiar can
then deliver the touch spell just as the master could. As usual, if the
master casts another spell before the touch is delivered, the touch
spell dissipates.</p>
    <p><a id="familiar-speak-with-master"></a><i>Speak with Master (Ex)</i>: If the master is 5th level or
higher, a familiar and the master can communicate verbally as if they
were using a common language. Other creatures do not understand the
communication without magical help.</p>
    <p><a id="familiar-speak-with-animals-of-its-kind"></a><i>Speak with Animals of Its Kind (Ex)</i>: If the master is
7th level or higher, a familiar can communicate with animals of
approximately the same kind as itself (including dire varieties): bats
with bats, rats with rodents, cats with felines, hawks and owls and
ravens with birds, lizards and snakes with reptiles, toads with
amphibians, weasels with similar creatures (weasels, minks, polecats,
ermines, skunks, wolverines, and badgers). Such communication is
limited by the intelligence of the conversing creatures.</p>
    <p><a id="familiar-spell-resistance"></a><i>Spell Resistance (Ex)</i>: If the master is 11th level or
higher, a familiar gains spell resistance equal to the master&#8217;s level +
5. To affect the familiar with a spell, another spellcaster must get a
result on a caster level check (1d20 + caster level) that equals or
exceeds the familiar&#8217;s spell resistance.</p>
    <p><a id="scry-on-familiar"></a><i>Scry on Familiar (Sp)</i>: If the master is 13th level or
higher, he may scry on his familiar (as if casting the scrying spell)
once per day.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}><a id="table-familiar-by-master-level"></a>Master
Class Level</th>
          <th style={{
            "width": "100px"
          }}>Natural
Armor Adj.</th>
          <th style={{
            "width": "50px"
          }}>Int</th>
          <th style={{
            "width": "350px"
          }}>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st&#8211;2nd</td>
          <td>+1</td>
          <td>6</td>
          <td>Alertness, improved evasion, share spells, empathic link</td>
        </tr>
        <tr>
          <td>3rd&#8211;4th</td>
          <td>+2</td>
          <td>7</td>
          <td>Deliver touch spells</td>
        </tr>
        <tr className="odd-row">
          <td>5th&#8211;6th</td>
          <td>+3</td>
          <td>8</td>
          <td>Speak with master</td>
        </tr>
        <tr>
          <td>7th&#8211;8th</td>
          <td>+4</td>
          <td>9</td>
          <td>Speak with animals of its kind</td>
        </tr>
        <tr className="odd-row">
          <td>9th&#8211;10th</td>
          <td>+5</td>
          <td>10</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>11th&#8211;12th</td>
          <td>+6</td>
          <td>11</td>
          <td>Spell resistance</td>
        </tr>
        <tr className="odd-row">
          <td>13th&#8211;14th</td>
          <td>+7</td>
          <td>12</td>
          <td>Scry on familiar</td>
        </tr>
        <tr>
          <td>15th&#8211;16th</td>
          <td>+8</td>
          <td>13</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17th&#8211;18th</td>
          <td>+9</td>
          <td>14</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="last-row">19th&#8211;20th</td>
          <td className="last-row">+10</td>
          <td className="last-row">15</td>
          <td className="last-row">&#8212;</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="arcane-spells-and-armor"></a>ARCANE SPELLS AND ARMOR</h5>
    <p className="initial">Wizards and sorcerers do not know how to wear
armor effectively. If desired, they can wear armor anyway (though
they&#8217;ll be clumsy in it), or they can gain training in the proper use
of armor (with the various Armor Proficiency feats&#8212;light, medium, and
heavy&#8212;and the Shield Proficiency feat), or they can multiclass to add a
class that grants them armor proficiency. Even if a wizard or sorcerer
is wearing armor with which he or she is proficient, however, it might
still interfere with spellcasting.</p>
    <p>Armor restricts the complicated gestures that a wizards or
sorcerer must make while casting any spell that has a somatic component
(most do). The armor and shield descriptions list the arcane spell
failure chance for different armors and shields.</p>
    <p>By contrast, bards not only know how to wear light armor
effectively, but they can also ignore the arcane spell failure chance
for such armor. A bard wearing armor heavier than light or using any
type of shield incurs the normal arcane spell failure chance, even if
he becomes proficient with that armor.</p>
    <p>If a spell doesn&#8217;t have a somatic component, an arcane
spellcaster can cast it with no problem while wearing armor. Such
spells can also be cast even if the caster&#8217;s hands are bound or if he
or she is <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a> (although <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
checks still apply
normally). Also, the metamagic feat <a href="featsAll.html#still-spell" style={{
        "color": "rgb(87, 158, 182)"
      }}>Still Spell</a> allows a spellcaster
to
prepare or cast a spell at one spell level higher than normal without
the somatic component. This also provides a way to cast a spell while
wearing armor without risking arcane spell failure.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      